* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
    
html,
body,
#__next {
    height: 100%;
    width: 100%;
}